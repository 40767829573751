export const transitionPlansData = {
    "AI Prompt Engineer": {
      currentRole: "Customer Service Representative",
      targetRole: "AI Prompt Engineer",
      duration: "6 months",
      phases: [
        {
          title: "Foundation Phase",
          duration: "6 weeks",
          progress: 0,
          milestones: [
            {
              title: "AI Fundamentals",
              duration: "2 weeks",
              status: "not-started",
              resources: [
                {
                  type: "Course",
                  title: "Introduction to AI and Machine Learning",
                  platform: "Coursera",
                  duration: "20 hours",
                  cost: "$49"
                },
                {
                  type: "Practice",
                  title: "Basic AI Concepts Quiz",
                  platform: "Internal",
                  duration: "2 hours",
                  cost: "Free"
                }
              ]
            },
            {
              title: "NLP Basics",
              duration: "2 weeks",
              status: "not-started",
              resources: [
                {
                  type: "Course",
                  title: "Natural Language Processing Fundamentals",
                  platform: "edX",
                  duration: "25 hours",
                  cost: "$99"
                }
              ]
            },
            {
              title: "Prompt Engineering Principles",
              duration: "2 weeks",
              status: "not-started",
              resources: [
                {
                  type: "Workshop",
                  title: "Prompt Engineering Basics",
                  platform: "Industry Expert Session",
                  duration: "15 hours",
                  cost: "$149"
                }
              ]
            }
          ]
        },
        {
          title: "Skill Building Phase",
          duration: "8 weeks",
          progress: 0,
          milestones: [
            {
              title: "Advanced NLP",
              duration: "3 weeks",
              status: "not-started",
              resources: [
                {
                  type: "Course",
                  title: "Advanced NLP Techniques",
                  platform: "Stanford Online",
                  duration: "30 hours",
                  cost: "$199"
                }
              ]
            },
            {
              title: "Practical Prompt Engineering",
              duration: "3 weeks",
              status: "not-started",
              resources: [
                {
                  type: "Project",
                  title: "Real-world Prompt Engineering",
                  platform: "Industry Project",
                  duration: "40 hours",
                  cost: "$299"
                }
              ]
            }
          ]
        },
        {
          title: "Practical Application Phase",
          duration: "10 weeks",
          progress: 0,
          milestones: [
            {
              title: "Portfolio Building",
              duration: "4 weeks",
              status: "not-started",
              resources: [
                {
                  type: "Project",
                  title: "Build Professional Portfolio",
                  platform: "Guided Project",
                  duration: "40 hours",
                  cost: "Free"
                }
              ]
            },
            {
              title: "Industry Certification",
              duration: "3 weeks",
              status: "not-started",
              resources: [
                {
                  type: "Certification",
                  title: "AI Prompt Engineering Certification",
                  platform: "Industry Standard Cert",
                  duration: "30 hours",
                  cost: "$399"
                }
              ]
            }
          ]
        }
      ]
    },
    "Data Analyst": {
      currentRole: "Data Entry Clerk",
      targetRole: "Data Analyst",
      duration: "8 months",
      phases: [
        // Similar structure for Data Analyst with its own phases...
      ]
    }
  };
  
  export const jobTransitions = [
    {
      fromJob: "Customer Service Representative",
      toJob: "AI Prompt Engineer",
      requiredSkills: ["Natural Language Processing", "Creative Writing", "AI Systems"],
      timeToAchieve: "6 months",
      matchScore: "85%",
      existingSkills: ["Communication", "Problem Solving"],
      skillsToAcquire: ["NLP", "AI Fundamentals"]
    },
    {
      fromJob: "Data Entry Clerk",
      toJob: "Data Analyst",
      requiredSkills: ["SQL", "Python", "Data Visualization"],
      timeToAchieve: "8 months",
      matchScore: "75%",
      existingSkills: ["Attention to Detail", "Data Entry"],
      skillsToAcquire: ["SQL", "Python"]
    }
  ];
  
  export const countryDemandData = {
    'United States': {
      'AI Prompt Engineer': { demand: 'Very High', salary: '$70,000 - $85,000' },
      'Data Analyst': { demand: 'High', salary: '$75,000 - $95,000' },
      'Project Coordinator': { demand: 'Medium', salary: '$60,000 - $75,000' }
    },
    'United Kingdom': {
      'AI Prompt Engineer': { demand: 'High', salary: '£40,000 - £55,000' },
      'Data Analyst': { demand: 'Very High', salary: '£45,000 - £65,000' },
      'Project Coordinator': { demand: 'Medium', salary: '£35,000 - £45,000' }
    }
  };